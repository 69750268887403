import React, { useEffect, useState } from "react";
import leftImg from './../images/cc-left.png';
import rightImg from './../images/cc-right.png';
import termsImage from './../images/terms.png';
import Loading from "./loading";
const Terms = () => {
  const [loading, setLoading] = useState(true);
  const [lang, setLang] = useState("al");
  const goBackHandler = () => {
    window.history.back();
  };
  useEffect(() => { setTimeout(() => { setLoading(false) }, 1500) }, [])


  const handleLangChange = (e) => {
    e.preventDefault();
    if (lang === "al") {
      window.localStorage.removeItem("lang");
      setTimeout(() => {
        window.localStorage.setItem("lang", "en");
        setLang("en");
      }, 200);
    } else {
      window.localStorage.removeItem("lang");
      setTimeout(() => {
        window.localStorage.setItem("lang", "al");
        setLang("al");
      }, 200);
    }
  };
  if (loading) {
    return (
      <Loading />
    )
  } else
    return (
      <>
        <div className=" container langWrapper my-4">
          <button className="form-control " onClick={handleLangChange}>
            {lang === "en" ? "English" : "Shqip"}
          </button>
          <button className="form-control " onClick={goBackHandler}>
            Go Back
          </button>
        </div>
        <div class="container success-container  mx-auto">
          <img src={leftImg} alt="letCCImg" className="leftImg" />
          <div className="alert alert-success">
            <h3 class="alert-heading text-center mb-3">Terms and Conditions</h3>
            <h5 className="text-center">Please Read Carefully!</h5>
            <hr />
            <div className='formControl overflow-auto1 ' id="style-2" style={{ overflow: 'auto', maxHeight: '65vh' }}>
              {lang == 'al' ?
                <>
                  <p>LOJA SHP&Euml;RBLYESE &ldquo;PROVO FATIN ME COCA COLA&rdquo;.</p>
                  <p>&nbsp;</p>
                  <p>ORGANIZATORI</p>
                  <p>&nbsp;</p>
                  <p>Neni 1</p>
                  <p>Coca-Cola Hellenic Bottling Company &ndash; Kosovo Coca-Cola Hellenic Bottling Company &ndash; Kosovo LLC, me seli n&euml; Prishtin&euml; n&euml; Laplasell&euml;, kilometri i pest&euml; i rrug&euml;s Prishtin&euml;-Shkup, 14000 Lipjan &ndash; Kosov&euml;, me num&euml;r tatimor 600299384, &euml;sht&euml; organizatore e loj&euml;s shp&euml;rblyese &ldquo;Provo fatin me Coca Cola&rdquo; (n&euml; tekstin e m&euml;tejm&euml; &ldquo;Loja Shp&euml;rblyese&rdquo;). K&euml;to rregulla jan&euml; t&euml; obligueshme p&euml;r t&euml; gjith&euml; pjes&euml;marr&euml;sit.</p>
                  <p>&nbsp;</p>
                  <p>EMRI ZYRTAR I LOJ&Euml;S SHP&Euml;RBLYESE</p>
                  <p>&nbsp;</p>
                  <p>Neni 2</p>
                  <p>Emri zyrtar i loj&euml;s shp&euml;rblyese &euml;sht&euml; &ldquo;Provo fatin me Coca Cola&rdquo;.</p>
                  <p>&nbsp;</p>
                  <p>TERRITORI</p>
                  <p>&nbsp;</p>
                  <p>Neni 3</p>
                  <p>Loja shp&euml;rblyese do t&euml; zhvillohet ekskluzivisht brenda territorit t&euml; Kosov&euml;s.</p>
                  <p>&nbsp;</p>
                  <p>KOH&Euml;ZGJATJA E LOJ&Euml;S SHP&Euml;RBLYESE</p>
                  <p>&nbsp;</p>
                  <p>Neni 4</p>
                  <p>Viti i Ri &lsquo;Coca Cola&rsquo; fillon m&euml; 1 dhjetor 2023, n&euml; or&euml;n 08:00 dhe p&euml;rfundon m&euml; 31 dhjetor 2023, n&euml; or&euml;n 23:59, Loja Shp&euml;rblyese u p&euml;rmbahet k&euml;tyre rregullave.</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <p>PAKETAT PROMOVUESE DHE Q&Euml;LLIMI I ORGANIZIMIT</p>
                  <p>&nbsp;</p>
                  <p>Neni 5</p>
                  <p>Produktet dhe paketat pjes&euml;marr&euml;se, t&euml; referuara s&euml; bashku si &ldquo;Paketa Promovuese&rdquo;, p&euml;rfshijn&euml; Coca Cola Red 0.25L n&euml; shishe qelqi t&euml; kthyeshme (n&euml; tekstin e m&euml;tejm&euml;: RGB), Coca Cola Zero 0,25L RGB, Coca Cola Red 0,33L dhe Coca Cola Zero 0,33L n&euml; kana&ccedil;e, dhe Coca Cola Red 0,45L, Coca Cola Zero 0,45L, Coca Cola Red 1,25L, Coca Cola Zero 1,25L, Cola Lemon 1.25L, Coca Cola Red 2L dhe Coca Cola Zero 2L n&euml; shishe plastike t&euml; pakthyeshme. Loja Shp&euml;rblyese synon q&euml; t&rsquo;i promovoj&euml; produktet e p&euml;rshkruara n&euml; k&euml;t&euml; artikull.</p>
                  <p>&nbsp;</p>
                  <p>Coca-Cola &euml;sht&euml; mark&euml; e regjistruar tregtare n&euml; pron&euml;si t&euml; &lsquo;Coca-Cola Company&rsquo;.</p>
                  <p>&nbsp;</p>
                  <p>E DREJTA P&Euml;R PJES&Euml;MARRJE</p>
                  <p>&nbsp;</p>
                  <p>Neni 6</p>
                  <p>T&euml; drejt&euml;n e pjes&euml;marrjes n&euml; Program e kan&euml; ekskluzivisht personat me banim n&euml; Republik&euml;n e Kosov&euml;s mbi mosh&euml;n 16 vje&ccedil;are.&nbsp;</p>
                  <p>&nbsp;</p>
                  <p>Personat e m&euml;posht&euml;m kan&euml; t&euml; drejt&euml; t&euml; mos marrin pjes&euml; n&euml; program:</p>
                  <p>- Personat q&euml; jan&euml; n&euml; marr&euml;dh&euml;nie pune ose t&euml; angazhuar ndryshe me Organizatorin si dhe bashk&euml;short&euml;t, prind&euml;rit dhe f&euml;mij&euml;t e tyre;</p>
                  <p>- Personat q&euml; jan&euml; n&euml; marr&euml;dh&euml;nie pune ose jan&euml; t&euml; angazhuar ndryshe me persona juridik q&euml; marrin pjes&euml; n&euml; &ccedil;far&euml;do m&euml;nyre n&euml; organizimin e Programit dhe/ose kan&euml; qasje n&euml; t&euml; dh&euml;nat n&euml; lidhje me p&euml;rgatitjen e programit, si dhe bashk&euml;short&euml;t, prind&euml;rit e tyre dhe f&euml;mij&euml;t.</p>
                  <p>&nbsp;</p>
                  <p>MEKANIZMI I PJES&Euml;MARRJES N&Euml; LOJ&Euml;N SHP&Euml;RBLYESE</p>
                  <p>&nbsp;</p>
                  <p>Neni 7</p>
                  <p>Pjes&euml;marr&euml;sit ndjekin hapat e m&euml;posht&euml;m p&euml;r t&euml; hyr&euml; n&euml; Loj&euml;n Shp&euml;rblyese:</p>
                  <p>&nbsp;</p>
                  <ol>
                    <li>Blini paket&euml;n promovuese</li>
                    <li>Ruajeni fatur&euml;n q&euml; v&euml;rteton se e keni bler&euml; paket&euml; promovuese</li>
                    <li>Vizitoni www.cokenewyear.com</li>
                    <li>Jepni t&euml; dh&euml;nat personale p&euml;r t&euml; marr&euml; pjes&euml; n&euml; loj&euml;:</li>
                  </ol>
                  <ul>
                    <li>Emri</li>
                    <li>Mbiemri</li>
                    <li>Telefoni</li>
                    <li>Adresa</li>
                    <li>Email</li>
                  </ul>
                  <ol start="5">
                    <li>Numri serik dhe numerik i fatur&euml;s</li>
                    <li>Ngarkoni foton e fatur&euml;s.</li>
                    <li>Aplikoni p&euml;r loj&euml;.</li>
                    <li>Prisni konfirmimin me email.</li>
                  </ol>
                  <img src={termsImage} alt="" className="img-fluid"/>
                  <p>&nbsp;</p>
                  <p>Pasi pjes&euml;marr&euml;si ta blej&euml; paket&euml;n promovuese, duhet q&euml; t&euml; identifikohet n&euml; uebsajtin www.cokenewyear.com (n&euml; tekstin e m&euml;tejm&euml;: uebsajti), ta regjistroj&euml; profilin duke futur adres&euml;n e emailit dhe informatat e k&euml;rkuara, t&euml; sh&euml;noj&euml; kodin serik dhe numerik t&euml; fatur&euml;s dhe foton p&euml;r t&euml; konfirmuar se kodi serik dhe ai numerik p&euml;rputhen. N&euml;se gjith&ccedil;ka &euml;sht&euml; sipas k&euml;rkes&euml;s, pjes&euml;marr&euml;si mund t&euml; kaloj&euml; dhe t&euml; aplikoj&euml; p&euml;r loj&euml;n. Fituesit dhe shp&euml;rblimet e fituara gjenerohen nga kompjuteri, me p&euml;rzgjedhje t&euml; rast&euml;sishme duke p&euml;rdorur algoritmin (n&euml; tekstin e m&euml;tejm&euml;: T&euml;rheqja).</p>
                  <p>Fituesi do t&euml; njoftohet p&euml;r shp&euml;rblimin e fituar p&euml;rmes e-mailit n&euml; 24 or&euml;t e ardhshme. Informatat e nevojshme p&euml;r dor&euml;zimin e shp&euml;rblimeve p&euml;rshkruhet n&euml; Nenin 10 t&euml; k&euml;tyre Rregullave. N&euml; rast se t&euml; dh&euml;nat personale jan&euml; t&euml; paplota ose t&euml; pasakta, shp&euml;rblimi nuk do t&euml; dor&euml;zohet. Mbledhja e t&euml; dh&euml;nave b&euml;het ekskluzivisht p&euml;r k&euml;t&euml; q&euml;llim, dhe m&euml; pas t&euml; dh&euml;nat fshihen, si&ccedil; p&euml;rshkruhet n&euml; paragrafin 10 t&euml; Nenit 7 (P&Euml;RPUNIMI I T&Euml; DH&Euml;NAVE PERSONALE).</p>
                  <p>P&euml;rzgjedhja e rast&euml;sishme do t&euml; bazohet n&euml; nj&euml; algorit&euml;m i cili mbledh&euml; t&euml; gjitha vlerat e dh&euml;na n&euml; p&euml;rzgjedhjen e fituesit dhe shpall&euml; fituesit n&euml; m&euml;nyr&euml; t&euml; rast&euml;sishme. P&euml;r shp&euml;rblimet ditore (T-shirt &ndash; 3 n&euml; dit&euml;, Hoodie - 3 n&euml; dit&euml;, Plush Toys - 16 n&euml; dit&euml;) algoritmi zgjedh nga t&euml; gjitha vlerat e dh&euml;na n&euml; dit&euml;n e p&euml;rzgjedhjes deri n&euml; or&euml;n 23:59 t&euml; s&euml; nj&euml;jt&euml;s dit&euml; dhe shpall&euml; fituesin t&euml; nes&euml;rmen. P&euml;r shp&euml;rblimet javore (TV &ndash; 1 n&euml; jav&euml;, makin&euml; Popcorn &ndash; 2 n&euml; jav&euml;, kamer&euml; Polaroid &ndash; 10 n&euml; jav&euml;), algoritmi zgjedh fituesit nga t&euml; gjitha vlerat e dh&euml;na n&euml; p&euml;rzgjedhjen e fituesit deri n&euml; fund t&euml; jav&euml;s (bazuar n&euml; afatin kohor p&euml;r shp&euml;rblimet javore m&euml; posht&euml;) n&euml; 23:59 dhe shpall&euml; fituesin t&euml; nes&euml;rmen. P&euml;r shp&euml;rblimin kryesor, algoritmi zgjedh fituesin nga t&euml; gjitha vlerat e dh&euml;na nga dita e par&euml; e promovimit 01.12 deri n&euml; dit&euml;n e fundit t&euml; promovimit 31.12 dhe fituesi shpallet t&euml; nes&euml;rmen.</p>
                  <p>Kodi serik dhe numerik mund t&euml; vendosen vet&euml;m nj&euml; her&euml; dhe mund&euml;son vet&euml;m nj&euml; p&euml;rpjekje p&euml;r t&euml; fituar ndonj&euml; shp&euml;rblim me t&euml; nj&euml;jtin kod serik dhe numerik. Fatura me t&euml; nj&euml;jtin num&euml;r serik dhe numerik mund t&euml; p&euml;rdoret vet&euml;m nj&euml; her&euml;.</p>
                  <p>P&euml;rpara se t&rsquo;i shtoni informatat, pjes&euml;marr&euml;sve u k&euml;rkohet q&euml; t&euml; konfirmojn&euml; se e kan&euml; lexuar dhe pajtohen me k&euml;to Rregulla, t&euml; disponueshme n&euml; uebsajt.</p>
                  <p>Organizatori rezervon t&euml; drejt&euml;n q&euml; t&euml; skualifikoj&euml; pjes&euml;marr&euml;sit q&euml; nuk i respektojn&euml; Rregullat ose q&euml; n&euml;p&euml;rmjet mashtrimit ose n&euml; ndonj&euml; m&euml;nyr&euml; tjet&euml;r t&euml; paligjshme bien n&euml; kund&euml;rshtim me frym&euml;n e loj&euml;s shp&euml;rblyese.</p>
                  <p>&nbsp;</p>
                  <p>P&Euml;RSHKRIMI I SHP&Euml;RBLIMEVE</p>
                  <p>Neni 8</p>
                  <p>Si komponent integral i loj&euml;s shp&euml;rblyese, fituesve u jepen gjithsej 753 shp&euml;rblime, t&euml; kategorizuara si m&euml; posht&euml;:</p>
                  <p>&nbsp;</p>
                  <p>Shp&euml;rblimet ditore (T-shirt, Hoodie, Plush Toys):</p>
                  <p>Algoritmi i zgjedh fituesit nga vlerat e dh&euml;na n&euml; dit&euml;n e p&euml;rzgjedhjes deri n&euml; or&euml;n 23:59 dhe shpall&euml; fituesin me e-mail t&euml; nes&euml;rmen.</p>
                  <p>&nbsp;</p>
                  <p>Shp&euml;rblimet javore (TV, makin&euml; Popcorn, kamer&euml; Polaroid):</p>
                  <p>Algoritmi i zgjedh fituesit nga vlerat e dh&euml;na n&euml; dit&euml;n e p&euml;rzgjedhjes deri n&euml; or&euml;n 23:59 dhe shpall&euml; fituesin me e-mail t&euml; nes&euml;rmen.</p>
                  <p>&nbsp;</p>
                  <p>Shp&euml;rblimi kryesor (iPhone 15 256 GB, i zi):</p>
                  <p>Fituesi zgjidhet me algorit&euml;m nga t&euml; gjitha vlerat e dh&euml;na gjat&euml; periudh&euml;s s&euml; promovimit nga 1 dhjetori 2023 deri m&euml; 31 dhjetor 2023. Njoftimi b&euml;het dit&euml;n e nes&euml;rme me e-mail. Vet&euml;m p&euml;r shp&euml;rblimin kryesor, fatura, me t&euml; cil&euml;n fituesi ka aplikuar, duhet t&euml; mbahet dhe t&rsquo;i jepet dor&euml;zuesit me rastin e marrjes s&euml; shp&euml;rblimit p&euml;r konfirmim, n&euml; t&euml; kund&euml;rt&euml;n, shp&euml;rblimi kryesor nuk i dor&euml;zohet fituesit.</p>
                  <p>&nbsp;</p>
                  <p>Afati kohor p&euml;r Shp&euml;rblimet Javore:</p>
                  <p>&nbsp;</p>
                  <p>Java I &ndash; 1.12.2023 deri m&euml; 7.12.2023. Shpallja e fituesit: 8.12.2023.</p>
                  <p>Java II &ndash; 8.12.2023 deri m&euml; 14.12.2023. Shpallja e fituesit: 15.12.2023.</p>
                  <p>Java III &ndash; 15.12.2023 deri m&euml; 21.12.2023. Shpallja e fituesit: 22.12.2023.</p>
                  <p>Java IV &ndash; 22.12.2023 deri m&euml; 31.12.2023. Shpallja e fituesit: 01.01.2024.</p>
                  <p>&nbsp;</p>
                  <p>Rregulloret e kodit serik dhe numerik:</p>
                  <p>&nbsp;</p>
                  <p>Kodi serik dhe numerik mund t&euml; vendosen vet&euml;m nj&euml; her&euml; dhe mund&euml;son vet&euml;m nj&euml; p&euml;rpjekje p&euml;r t&euml; fituar ndonj&euml; shp&euml;rblim me t&euml; nj&euml;jtin kod. Fatura me t&euml; nj&euml;jtin kod serik dhe numerik mund t&euml; p&euml;rdoret vet&euml;m nj&euml; her&euml;.</p>
                  <p>&nbsp;</p>
                  <p>Konfirmimi i marr&euml;veshjes:</p>
                  <p>P&euml;rpara se t&rsquo;i shtojn&euml; informatat, pjes&euml;marr&euml;sve u k&euml;rkohet q&euml; t&euml; konfirmojn&euml; se e kan&euml; lexuar dhe pajtohen me k&euml;to Rregulla, t&euml; disponueshme n&euml; uebsajt.</p>
                  <p>&nbsp;</p>
                  <p>Klauzola e skualifikimit:</p>
                  <p>Organizatori rezervon t&euml; drejt&euml;n q&euml; t&euml; skualifikoj&euml; pjes&euml;marr&euml;sit q&euml; nuk i respektojn&euml; Rregullat ose q&euml; n&euml;p&euml;rmjet mashtrimit ose n&euml; ndonj&euml; m&euml;nyr&euml; tjet&euml;r t&euml; paligjshme bien n&euml; kund&euml;rshtim me frym&euml;n e loj&euml;s shp&euml;rblyese.</p>
                  <p>&nbsp;</p>
                  <p>Shp&euml;rblimi kryesor:</p>
                  <p>&nbsp;</p>
                  <p>iPhone 15 256 GB, i zi (fatura, t&euml; cil&euml;n pjes&euml;marr&euml;si e ka futur me t&euml; dh&euml;nat personale, duhet t&euml; mbahet dhe t&rsquo;ia dor&euml;zoj&euml; post&euml;s me rastin e marrjes s&euml; shp&euml;rblimit).</p>
                  <p>&nbsp;</p>
                  <p>Shp&euml;rblimet javore:</p>
                  <p>&nbsp;</p>
                  <ul>
                    <li>4 TV LG55 in&ccedil;, i zi (1 n&euml; jav&euml;).</li>
                    <li>8 makina Popcorn (2 n&euml; jav&euml;).</li>
                    <li>40 kamera Polaroid (10 n&euml; jav&euml;).</li>
                  </ul>
                  <p>&nbsp;</p>
                  <p>Shp&euml;rblimet ditore:</p>
                  <p>&nbsp;</p>
                  <ul>
                    <li>100 NY Hoodies, nj&euml; madh&euml;si (3 n&euml; dit&euml;).</li>
                    <li>100 NY T-shirts, nj&euml; madh&euml;si (3 n&euml; dit&euml;).</li>
                    <li>500 Plush Toys (3 komplete) (16 n&euml; dit&euml;).</li>
                  </ul>
                  <p>&nbsp;</p>
                  <p>Rregulloret e shp&euml;rblimeve:</p>
                  <p>&nbsp;</p>
                  <p>Shp&euml;rblimet nuk mund t&euml; shk&euml;mbehen p&euml;r para t&euml; gatshme.</p>
                  <p>Shp&euml;rblimet nuk mund t&rsquo;u transferohen personave t&euml; tjer&euml;.</p>
                  <p>Organizatori garanton shp&euml;rndarjen e shp&euml;rblimeve fituesve, jo m&euml; shum&euml; se 2 jav&euml; pasi ta ken&euml; marr&euml; konfirmimin me email p&euml;r shp&euml;rblimin e fituar.</p>
                  <p>&nbsp;</p>
                  <p>P&Euml;RPUNIMI I T&Euml; DH&Euml;NAVE PERSONALE</p>
                  <p>&nbsp;</p>
                  <p>T&euml; dh&euml;nat personale t&euml; mbledhura do t&euml; p&euml;rdoren ekskluzivisht p&euml;r q&euml;llime t&euml; shp&euml;rndarjes s&euml; shp&euml;rblimeve dhe nuk i p&euml;rcillen asnj&euml; pale t&euml; tret&euml; n&euml; koordinim me rregulloren e p&euml;rgjithshme p&euml;r mbrojtjen e t&euml; dh&euml;nave (GDPR), p&euml;rve&ccedil; Agjencis&euml; VM# e cila agjenci &euml;sht&euml; e angazhuar p&euml;r shp&euml;rndarjen e shp&euml;rblimeve. T&euml; gjitha t&euml; dh&euml;nat do t&euml; fshihen pas p&euml;rfundimit t&euml; Loj&euml;s Shp&euml;rblyese.</p>
                  <p>&nbsp;</p>
                  <p>OBLIGIMET E ORGANIZATORIT DHE PJES&Euml;MARR&Euml;SIT</p>
                  <p>&nbsp;</p>
                  <p>Neni 10</p>
                  <p>Organizatori siguron realizimin e duhur t&euml; Loj&euml;s Shp&euml;rblyese, ruan konfidencialitetin e t&euml; dh&euml;nave t&euml; pjes&euml;marr&euml;sve dhe u p&euml;rgjigjet ankesave. Pjes&euml;marr&euml;sit duhet t&rsquo;i respektojn&euml; rregullat, t&euml; japin t&euml; dh&euml;na t&euml; sakta dhe t&euml; ruajn&euml; fatur&euml;n origjinale p&euml;r shp&euml;rblimet kryesore deri n&euml; fund t&euml; loj&euml;s shp&euml;rblyese.</p>
                  <p>&nbsp;</p>
                  <p>PROCEDURA E REALIZIMIT T&Euml; S&Euml; DREJT&Euml;S P&Euml;R SHP&Euml;RBLIM</p>
                  <p>&nbsp;</p>
                  <p>Neni 11</p>
                  <p>Pjes&euml;marr&euml;sit do ta kuptojn&euml; se jan&euml; fituesi i p&euml;rzgjedhur duke marr&euml; nj&euml; porosi me e-mail (Email i Konfirmimit). Dukja e Emailit t&euml; Konfirmimit p&euml;r fituesit e shp&euml;rblimeve &euml;sht&euml; si m&euml; posht&euml;:&nbsp;</p>
                  <p>Urime! Me sukses ke fituar _______________ nga Coca Cola New Year NCP.</p>
                  <p>&nbsp;</p>
                  <p>*T&euml; gjith&euml; fituesit do t&rsquo;i marrin shp&euml;rblimet e tyre jo m&euml; von&euml; se 15 janari 2024.</p>
                  <p>&nbsp;</p>
                  <p>Q&euml; shp&euml;rblimi t&euml; dor&euml;zohet ose t&euml; merret, &euml;sht&euml; e nevojshme q&euml; t&euml; gjitha t&euml; dh&euml;nat e k&euml;rkuara t&euml; jen&euml; t&euml; sakta dhe t&rsquo;i referohen nj&euml; personi, dhe n&euml; rast se jan&euml; dh&euml;n&euml; t&euml; dh&euml;nat personale t&euml; paplota ose t&euml; pasakta, shp&euml;rblimi nuk do t&euml; dor&euml;zohet.</p>
                  <p>Shp&euml;rblimet dor&euml;zohen n&euml;p&euml;rmjet agjencis&euml; VM3 n&euml; adres&euml;n e deklaruar nga fituesi, jo m&euml; von&euml; se 2 jav&euml; pasi fituesi ta ket&euml; marr&euml; emailin. T&euml; drejt&euml;n p&euml;r ta marr&euml; shp&euml;rblimin e ka vet&euml;m personi i specifikuar n&euml; informatat e dor&euml;zuara n&euml; adres&euml;n e emailit pas fitimit t&euml; shp&euml;rblimit e jo ndonj&euml; person tjet&euml;r. N&euml; rast se fituesi nuk &euml;sht&euml; n&euml; adres&euml;n e dh&euml;n&euml; n&euml; koh&euml;n e dor&euml;zimit, VM3 do t&euml; p&euml;rpiqet q&euml; ta dor&euml;zoj&euml; shp&euml;rblimin edhe dy her&euml; t&euml; tjera, n&euml; t&euml; nj&euml;jt&euml;n adres&euml;. N&euml;se fituesi nuk &euml;sht&euml; n&euml; adres&euml; gjat&euml; k&euml;tyre dy herave, fituesi e humb t&euml; drejt&euml;n e shp&euml;rblimit.</p>
                  <p>&nbsp;</p>
                  <p>DISPOZITAT P&Euml;RFUNDIMTARE</p>
                  <p>&nbsp;</p>
                  <p>Neni 12</p>
                  <p>Pjes&euml;marr&euml;sit konfirmojn&euml; se i kan&euml; kuptuar dhe i pranojn&euml; k&euml;to rregulla. Organizatori rezervon t&euml; drejt&euml;n p&euml;r t&euml; ndryshuar rregullat, t&euml; cilat hyjn&euml; n&euml; fuqi me rastin e publikimit n&euml; www.cokenewyear.com. Pjes&euml;marr&euml;sit do t&euml; informohen p&euml;r ndryshimet n&euml; t&euml; nj&euml;jtin uebsajt. Organizatori nuk mban p&euml;rgjegj&euml;si p&euml;r &ccedil;&euml;shtjet teknike q&euml; pengojn&euml; qasjen n&euml; uebsajt.</p>
                  <p>&nbsp;</p>
                  <p>P&euml;r &ccedil;&euml;shtjet q&euml; nuk mbulohen nga k&euml;to rregulla, aplikohen ligjet e Republik&euml;s s&euml; Kosov&euml;s.</p>
                  <p>&nbsp;</p>
                  <p>Neni 13</p>
                  <p>Ankesat duhet t&euml; dor&euml;zohen me shkrim n&euml; adres&euml;n e Organizatorit t&euml; specifikuar n&euml; Nenin 1 brenda 10 dit&euml;ve nga shkelja e pretenduar. Organizatori p&euml;rgjigjet brenda 15 dit&euml;ve.</p>
                  <p>&nbsp;</p>
                  <p>Neni 14</p>
                  <p>N&euml; rast t&euml; mosp&euml;rputhjes, mbizot&euml;ron interpretimi i Organizatorit.</p>
                  <p>&nbsp;</p>
                  <p>Neni 15</p>
                  <p>Gjykata kompetente n&euml; Prishtin&euml; ka juridiksion p&euml;r kontestet q&euml; burojn&euml; nga Loja Shp&euml;rblyese.</p>
                  <p>&nbsp;</p>
                  <p>Neni 16</p>
                  <p>K&euml;to rregulla jan&euml; t&euml; disponueshme n&euml; www.cokenewyear.com.</p>
                  <p>&nbsp;</p>
                  <p>Neni 17</p>
                  <p>N&euml; rast se ka diskrepanc&euml; mes versionit shqip dhe anglisht, versioni n&euml; gjuhen angleze ka p&euml;rpar&euml;si.</p>
                  <p>&nbsp;</p>
                  <p>P&euml;rkrahja e klientit: 049 74 55 70</p>
                  <p>Prishtin&euml;, 20 n&euml;ntor 2023.</p>
                  <p>Coca-Cola Hellenic Bottling Company - Kosovo LLC, Prishtin&euml;</p>
                </>
                :

                <>
                  <p>TRY YOUR LUCK WITH COKE" PRIZE GAME</p>
                  <p>&nbsp;</p>
                  <p>ORGANIZER</p>
                  <p>&nbsp;</p>
                  <p>Article 1</p>
                  <p>Coca-Cola Hellenic Bottling Company &ndash; Kosovo Coca-Cola Hellenic Bottling Company - Kosovo LLC, based in Pristina at Laplasell&euml;, Fifth Kilometer of Pristina-Skopje Road, 14000 Lipjan &ndash; Kosovo, tax number 600299384, is the organizer of the "Try Your Luck with Coke" prize game (hereinafter referred to as the "Prize Game"). These rules are binding for all participants.</p>
                  <p>&nbsp;</p>
                  <p>OFFICIAL NAME OF THE PRIZE GAME</p>
                  <p>&nbsp;</p>
                  <p>Article 2</p>
                  <p>The official name of the Prize Game is "Try Your Luck with Coke."</p>
                  <p>&nbsp;</p>
                  <p>TERRITORY</p>
                  <p>&nbsp;</p>
                  <p>Article 3</p>
                  <p>The Prize Game will take place exclusively within the territory of Kosovo.</p>
                  <p>&nbsp;</p>
                  <p>DURATION OF THE PRIZE GAME</p>
                  <p>&nbsp;</p>
                  <p>Article 4</p>
                  <p>Coke New Year starts on December 1, 2023, at 08:00, and ends on December 31, 2023, at 23:59, the Prize Game will adhere to these rules.</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <p>PROMOTIONAL PACKAGES AND ORGANIZATION PURPOSE</p>
                  <p>&nbsp;</p>
                  <p>Article 5</p>
                  <p>Participating products and packages, collectively referred to as "Promotional Packages," include Coca Cola Red 0.25L in returnable glass bottle (hereinafter: RGB), Coca Cola Zero 0.25L RGB, Coca Cola Red 0.33L and Coca Cola Zero 0.33L in cans, and Coca Cola Red 0.45L, Coca Cola Zero 0.45L, Coca Cola Red 1.25L, Coca Cola Zero 1.25L, Coca Cola Lemon 1.25L, Coca Cola Red 2L, and Coca Cola Zero 2L in non-returnable plastic bottles. The Prize Game aims to promote the products outlined in this article.</p>
                  <p>&nbsp;</p>
                  <p>Coca-Cola is a registered trademark owned by The Coca-Cola Company.</p>
                  <p>&nbsp;</p>
                  <p>RIGHT TO PARTICIPATE</p>
                  <p>&nbsp;</p>
                  <p>Article 6</p>
                  <p>The right to participate in the Program is exclusively for persons residing in the Republic of Kosovo above 16 years old.&nbsp;</p>
                  <p>&nbsp;</p>
                  <p>The following persons have the right not to participate in the program:</p>
                  <p>- Persons who are in a working relationship or otherwise engaged with the Organizer as well as their spouses, parents and children;</p>
                  <p>- Persons who are in a working relationship or are otherwise engaged with legal entities that participate in any way in the organization and organization of the Program and/or have access to data related to the preparation of the program, as well as their spouses, parents and children.</p>
                  <p>&nbsp;</p>
                  <p>PARTICIPATION MECHANISM IN THE PRIZE GAME</p>
                  <p>&nbsp;</p>
                  <p>Article 7</p>
                  <p>Participants follow the below steps to enter the Prize Game:</p>
                  <p>&nbsp;</p>
                  <ol>
                    <li>Buy Promotional package</li>
                    <li>Save receipt that proves you bought Promotional package</li>
                    <li>Visit www.cokenewyear.com</li>
                    <li>Provide personal data in order to participate in the game:</li>
                  </ol>
                  <ul>
                    <li>Name</li>
                    <li>Surname</li>
                    <li>Phone</li>
                    <li>Address</li>
                    <li>Email</li>
                  </ul>
                  <ol start="5">
                    <li>Serial and numeric number of the invoice</li>
                    <li>Upload a matching picture of the invoice.</li>
                    <li>Apply for the game.</li>
                    <li>Await an email confirmation.</li>
                  </ol>
                  <img src={termsImage} alt="" className="img-fluid" />
                  <p>&nbsp;</p>
                  <p>After the participant buys Promotional package, it is necessary to log on to the website www.cokenewyear.com (hereinafter: website), register their profile by entering their email address and the required information, enter the serial and numeric code of the invoice, and take a picture to confirm that the serial and numeric code match. If everything is according to the request, the participant can go through and apply for the game. Winners and the prizes won are generated by computer, randomly selected using the algorithm (hereinafter: Draw).</p>
                  <p>The winner will be informed about the prize won through e-mail in the next 24 hours. The information needed to deliver the prizes is described in Article 10 of these Rules. In case of incomplete or inaccurate personal data, the prize will not be delivered. Data collection will be done exclusively for this purpose, and then the data will be deleted as described in paragraph 10 of Article 7 (PROCESSING OF PERSONAL DATA).</p>
                  <p>The random selection will be based on an algorithm which collects all the values provided upon the winner selection and announces the winners randomly. For daily prizes (T-shirt 3 per day, Hoodie 3 per day, Plush Toys 16 per day) algorithm will select from all the values provided in the selection day until 23:59h of the same day and announces the winner on next day. For Weekly prizes (TV 1 per week, Popcorn machine 2 per week, Polaroid cam 10 per week), algorithm selects winners from all the values provided upon the winner selection until end of week (based on the timeline for the weekly prizes below) at 23:59h and announces the winner on the next day. For the main prize, algorithm selects winner from all the values provided from the first day of promotion 01.12 until the last day of promotion 31.12 and the winner will be announced on the next day.&nbsp;</p>
                  <p>The serial and numeric can be set only once and allows only one attempt to win a prize with the same serial and numeric number. The invoice with the same serial and numeric number can be used only once.</p>
                  <p>Before adding the info, participants will be required to confirm that they have read and agreed to these Rules, available on the website.</p>
                  <p>The Organizer reserves the right to disqualify participants who do not respect the Rules or who, through fraud or in any other unlawful manner or in contradiction with the spirit of the Prize Game.</p>
                  <p>&nbsp;</p>
                  <p><strong>DESCRIPTION OF PRIZES </strong></p>
                  <p>Article 8</p>
                  <p>As an integral component of the Prize Game, a total of 753 prizes will be awarded to winners, categorized as follows:</p>
                  <p>&nbsp;</p>
                  <p><strong>Daily Prizes (T-shirt, Hoodie, Plush Toys):</strong></p>
                  <p>The algorithm selects winners from the values provided on the selection day until 23:59h and announces the winner via e-mail in the next day.</p>
                  <p>&nbsp;</p>
                  <p><strong>Weekly Prizes (TV, Popcorn machine, Polaroid cam):</strong></p>
                  <p>The algorithm selects winners from the values provided on the selection day until 23:59h and announces the winner via e-mail in the next day.</p>
                  <p>&nbsp;</p>
                  <p><strong>Main Prize (iPhone 15 256 GB, black):</strong></p>
                  <p>The winner is selected by algorithm from all values provided throughout the promotion period from December 1, 2023, to December 31, 2023. The announcement will be made on the next day through e-mail. Only for the main prize the invoice winner applied with should be kept and give to the currie performing delivery after receiving the gift for confirmation, otherwise, main prize shell not be delivered to the winner.</p>
                  <p>&nbsp;</p>
                  <p><strong>Timeline for Weekly Prizes:</strong></p>
                  <p>&nbsp;</p>
                  <p>I week &ndash; 1.12.2023 to 7.12.2023. Winner announcement: 8.12.2023.</p>
                  <p>II week &ndash; 8.12.2023 to 14.12.2023. Winner announcement: 15.12.2023.</p>
                  <p>III week &ndash; 15.12.2023 to 21.12.2023. Winner announcement: 22.12.2023.</p>
                  <p>IV week &ndash; 22.12.2023 to 31.12.2023. Winner announcement: 01.01.2024.</p>
                  <p><strong>&nbsp;</strong></p>
                  <p><strong>&nbsp;</strong></p>
                  <p><strong>Serial and Numeric Code Regulations:</strong></p>
                  <p>&nbsp;</p>
                  <p>The serial and numeric code can be set only once, allowing a single attempt to win a prize with the same code. The invoice with the same serial and numeric code can be used only once.</p>
                  <p>&nbsp;</p>
                  <p><strong>Confirmation of Agreement:</strong></p>
                  <p>Before adding their information, participants will be required to confirm that they have read and agreed to these Rules, which are available on the website.</p>
                  <p>&nbsp;</p>
                  <p><strong>Disqualification Clause:</strong></p>
                  <p>The Organizer reserves the right to disqualify participants who do not adhere to the Rules, engage in fraudulent activities, or act in any other unlawful manner, contradicting the spirit of the Prize Game.</p>
                  <p>&nbsp;</p>
                  <p><strong>Main Prize:</strong></p>
                  <p>&nbsp;</p>
                  <p>iPhone 15 256 GB, black (the Invoice participant put into the details must be kept and delivered to post during receiving the gift).</p>
                  <p>&nbsp;</p>
                  <p>Weekly Prizes:</p>
                  <p>&nbsp;</p>
                  <ul>
                    <li>4 LG55 inch TVs, black (1 per week).</li>
                    <li>8 Popcorn Machines (2 per week).</li>
                    <li>40 Polaroid Cameras (10 per week).</li>
                  </ul>
                  <p>&nbsp;</p>
                  <p>Daily Prizes:</p>
                  <p>&nbsp;</p>
                  <ul>
                    <li>100 NY Hoodies, one size (3 per day).</li>
                    <li>100 NY T-shirts, one size (3 per day).</li>
                    <li>500 Plush Toys (3 sets) (16 per day).</li>
                  </ul>
                  <p>&nbsp;</p>
                  <p><strong>Prize Regulations:</strong></p>
                  <p>&nbsp;</p>
                  <p>Prizes cannot be exchanged for cash.</p>
                  <p>Prizes cannot be transferred to other persons.</p>
                  <p>The Organizer guarantees the distribution of prizes to the winners, in no longer than 2 weeks&rsquo; after they receive confirmation from the prize they have won via e-mail.</p>
                  <p>&nbsp;</p>
                  <p>PROCESSING OF PERSONAL DATA</p>
                  <p>&nbsp;</p>
                  <p>Personal data collected will be used exclusively for prize delivery purposes and will not be forwarded to any third party in coordination with general data protection regulation(GDPR)., except VM# Agency which agency is engaged for prizes delivery.All data will be deleted post-Prize Game completion.</p>
                  <p>&nbsp;</p>
                  <p>OBLIGATIONS OF THE ORGANIZER AND PARTICIPANT</p>
                  <p>&nbsp;</p>
                  <p>Article 10</p>
                  <p>The Organizer ensures proper implementation of the Prize Game, maintains participant data confidentiality, and responds to complaints. Participants must respect the rules, provide accurate data, and retain the original invoice for main prizes until the end of the Prize Game.</p>
                  <p>&nbsp;</p>
                  <p>PROCEDURE FOR REALIZING THE RIGHT TO THE PRIZE</p>
                  <p>&nbsp;</p>
                  <p>Article 11</p>
                  <p>Participants will know when they have been the selected winner by receiving an e-mail in their inbox (Confirmation Email). The layout of the Confirmation Email for award winners is as follows:&nbsp;</p>
                  <p><em>Congratulations! You have successfully won _______________ from Coca Cola New Year NCP. </em></p>
                  <p><em>&nbsp;</em></p>
                  <p><em>*All winners will receive their prizes no later than 15<sup>th</sup> of January 2024.</em></p>
                  <p><em>&nbsp;</em></p>
                  <p>For the prize to be delivered or received, it is necessary for all the requested data to be correct and to refer to a person in case of submission of incomplete or incorrect personal data, the gift will not be delivered.</p>
                  <p>Prizes will be delivered via VM3 agency to the address reported by the winner, no later than 2 weeks after the winner receives the email. The right to receive the prize will have only the person specified in the information submitted to the e-mail address after winning the prize and no other person. In case the winner is not at the address at the time of delivery, VM3 will try to deliver the prize two more times, at the same address. If the winner is not at the address during those two times, the winner loses the right to prize..</p>
                  <p>&nbsp;</p>
                  <p>FINAL PROVISIONS</p>
                  <p>&nbsp;</p>
                  <p>Article 12</p>
                  <p>Participants, by participating, confirm understanding and acceptance of these rules. The Organizer reserves the right to change rules, effective upon publication on www.cokenewyear.com. Participants will be informed of changes on the same website. The Organizer is not liable for technical issues preventing website access.</p>
                  <p>&nbsp;</p>
                  <p>For matters not covered by these rules, the laws of the Republic of Kosovo apply.</p>
                  <p>&nbsp;</p>
                  <p>Article 13</p>
                  <p>Complaints must be submitted in writing to the Organizer's address specified in Article 1 within 10 days of the alleged violation. The Organizer responds within 15 days.</p>
                  <p>&nbsp;</p>
                  <p>Article 14</p>
                  <p>In case of discrepancies, the Organizer's interpretation prevails.</p>
                  <p>&nbsp;</p>
                  <p>Article 15</p>
                  <p>The competent court in Pristina has jurisdiction over disputes arising from the Prize Game.</p>
                  <p>&nbsp;</p>
                  <p>Article 16</p>
                  <p>These rules are available on www.cokenewyear.com.</p>
                  <p>&nbsp;</p>
                  <p>Customer Support: 049 74 55 70</p>
                  <p>Pristina, November 20, 2023.</p>
                  <p>Coca-Cola Hellenic Bottling Company - Kosovo LLC, Pristina</p>
                </>
              }
            </div>
          </div>
          <img src={rightImg} alt="rightCCImg" className="rightImg" />
        </div>
      </>
    );
}

export default Terms;